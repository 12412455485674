import React, { useState, useReducer, useEffect } from 'react';
import moment from 'moment';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import { useMutation } from '@apollo/react-hooks';

import RootDOM from './RootDOM';

import { ModalContextProvider } from './contexts/modalContext';
import { NewPostProvider } from './contexts/newPostContext';
import { AuthContextProvider } from './contexts/authContext';
import { ErrorContextProvider } from './contexts/errorContext';
import { GET_NEW_TOKEN } from './Queries/Mutation/token';
import { parseJwt } from './components/util/parseJwt';

const engine = new Styletron();

let authFromLocalStorage = localStorage.getItem('auth');
if (authFromLocalStorage && authFromLocalStorage !== undefined) {
  authFromLocalStorage = JSON.parse(authFromLocalStorage);
}

let tokenData = null;
if (authFromLocalStorage) {
  tokenData = parseJwt(authFromLocalStorage.token);
}

function App() {
  const [modalStatus, setModalStatus] = useState(false);
  const [getNewToken] = useMutation(GET_NEW_TOKEN);
  const [modalData, setModalData] = useState(null);
  const [auth, setAuth] = useState(authFromLocalStorage);
  const [errors, dispatchError] = useReducer((state, action) => {
    switch (action.type) {
      case 'ADD_ERROR':
        return [action.payload, ...state];
      case 'REMOVE_ERROR':
        return state.filter(eachError => {
          if (eachError.id === action.payload) {
            return false;
          } else {
            return true;
          }
        });
      default:
        return state;
    }
  }, []);

  const removeError = id => {
    dispatchError({ type: 'REMOVE_ERROR', payload: id });
  };

  const addError = ({ message, error }) => {
    const id = Date.now();
    dispatchError({ type: 'ADD_ERROR', payload: { message, error, id } });
    setTimeout(() => {
      removeError(id);
    }, 5000);
  };

  const [newPost, setNewPost] = useState({
    context: '',
    heading: '',
    subHeadings: [],
    slug: Math.random()
      .toString()
      .slice(2, 8),
    permalink: '/',
    summary: '',
    body: '',
    authors: [],
    medias: [],
    categories: [],
    homePagePlacement: 'LOW',
    postEvent: {
      date: new Date(new Date(moment().subtract(1, 'day')).toDateString()),
      nation: 'Nepal',
      placeName: '',
      localGovernment: null
    },
    printedPublishedDate: null,
    publishedPageNumber: '',
    publishedEdition: 'Ghorahi',
    status: 'PUBLISHED',
    labels: ''
  });

  useEffect(() => {
    if (!tokenData) return;
    if (tokenData.exp * 1000 < Date.now()) {
      addError({ message: 'Session Expired', error: true });
      localStorage.removeItem('auth');
      setAuth(null);
      return;
    }

    setTimeout(async () => {
      const responseData = await getNewToken({
        variables: {
          token: authFromLocalStorage.token
        }
      });

      setAuth({ ...auth, token: responseData.data.getNewToken });
    }, tokenData.exp * 1000 - Date.now() - 60 * 60 * 1000);
  }, []);

  return (
    <div className="App">
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <AuthContextProvider value={{ auth, setAuth }}>
            <ErrorContextProvider value={{ errors, addError, removeError }}>
              <NewPostProvider value={{ post: newPost, setPost: setNewPost }}>
                <ModalContextProvider
                  value={{
                    modalStatus,
                    setModalStatus,
                    modalData,
                    setModalData
                  }}
                >
                  <RootDOM />
                </ModalContextProvider>
              </NewPostProvider>
            </ErrorContextProvider>
          </AuthContextProvider>
        </BaseProvider>
      </StyletronProvider>
    </div>
  );
}

export default App;
