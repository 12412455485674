import React from "react";
import App from "./App";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";
import { Global } from "./styles/Global.jsx";
import { ApolloLink } from "apollo-link";

const httpLink = createHttpLink({
  // uri: "http://localhost:5000/graphql",
  uri: "https://nayayougbodh.com/graphql",
  // uri: "/graphql"
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const auth = localStorage.getItem("auth");
  let token = null;
  if (auth) {
    token = JSON.parse(auth).token;
  }

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      auth: token ? token : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});

export default (
  <ApolloProvider client={client}>
    <Global />
    <App />
  </ApolloProvider>
);
