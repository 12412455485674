import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'baseui/spinner';

const ActivityLoader = ({ customStyles }) => {
  return (
    <CenterUsingPositionAbsolute style={customStyles}>
      <Spinner
        overrides={{
          TrackPath: {
            style: {
              fill: '#f0f0f0'
            }
          },
          ActivePath: {
            style: {
              fill: '#333'
            }
          }
        }}
      />
    </CenterUsingPositionAbsolute>
  );
};

const CenterUsingPositionAbsolute = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default ActivityLoader;
