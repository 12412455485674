import React, { useContext, lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import ErrorBoundary from './components/ErrorBoundary';

import { modalContext } from './contexts/modalContext';
import Spinner from './components/util/Spinner';

const Modal = lazy(() => import('./components/util/Modal'));
const Error = lazy(() => import('./components/Error'));
const Routes = lazy(() => import('./Routes'));
const Navigation = lazy(() => import('./components/Navigation'));

const RootDOM = ({ auth, setAuth }) => {
  const { modalStatus, setModalStatus, modalData } = useContext(modalContext);

  const FullScreenSpinner = (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <Spinner />
    </div>
  );
  return (
    <Suspense fallback={FullScreenSpinner}>
      <Modal active={modalStatus} close={() => setModalStatus(false)}>
        {modalData}
      </Modal>
      <Router>
        <Navigation />
        <Container>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Container>
      </Router>
      <Error />
    </Suspense>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  min-height: calc(100vh - 10rem);
  margin-top: 10rem;
  margin-left: 12.5rem;
  font-size: 1.8rem;
  position: relative;
`;

export default RootDOM;
